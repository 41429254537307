<template>
  <el-card class="associated">
    <div class="associated_top">
      <div>
        <el-button type="primary" v-if="$hasPerms('system:add')" @click="addaccount()"
          >新增</el-button
        >
      </div>
      <div class="search_right">
        <el-input
          placeholder="请输入企业名称"
          v-model="queryInfo.keys"
          class="input-with-select"
          clearable
        >
          <el-button slot="append" @click="getCorrelateUser">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="switch_tab">
      <div class="label_management">
        <el-table
          :data="listData"
          stripe
          @sort-change="changeSort"
          style="width: 100%"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column label="ID" align="center" prop="id"></el-table-column>
          <el-table-column label="手机号" prop="mobile" align="center"></el-table-column>

          <el-table-column label="真实姓名" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色" prop="" align="center">
            <template v-slot="{ row }">
              <span>{{ row.roleName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span>{{ row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最后登录时间" prop="loginTime" align="center">
            <template v-slot="{ row }">
              <span>{{ row.loginTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登录状态" prop="time" align="center">
            <template v-slot="{ row }">
              <div v-if="row.userChannel == 1">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="平台创建"
                  placement="top-start"
                >
                <el-switch
                disabled
                  active-color="#1E9FFF"
                  v-model="row.notState"
                  :active-text="row.notState ? '启用' : '禁用'"
                >
                </el-switch>
                </el-tooltip>
              </div>
              <div v-else>
                <el-switch
                  active-color="#1E9FFF"
                  @change="notState(row)"
                  v-model="row.notState"
                  :active-text="row.notState ? '启用' : '禁用'"
                >
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="登录次数" prop="loginCount" align="center" sortable="">
            <template v-slot="{ row }">
              <span>{{ row.loginCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <div v-if="$hasPerms('menu:edit')">
                <div v-if="scope.row.userChannel == 1">
                <el-button style="color: #146aff" type="text" disabled>编辑
              </el-button>
              </div>
              <div v-else>
                <el-button style="color: #146aff" type="text" @click="editphone(scope.row)"
                >编辑
              </el-button>
              </div>
              </div>
             
              

              <div v-if="$hasPerms('system:freeze')">
                <el-button
                  v-if="scope.row.notDelete == 0"
                  style="color: #fd5469"
                  type="text"
                  @click="accoutClick(scope.row)"
                  >冻结
                </el-button>
                <el-button
                  v-if="scope.row.notDelete == 1"
                  style="color: #146aff"
                  type="text"
                  @click="accoutClick(scope.row)"
                  >激活
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>

    <el-dialog
      title="新增"
      :visible.sync="accountTypeDialogVisible"
      width="500px"
      class="account_dialog"
    >
      <el-form label-width="100px" :model="adminUser" :rules="adminrules" ref="accountTypeDialog">
        <el-form-item label="手机号:" prop="mobile">
          <el-input v-model="adminUser.mobile" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="真实姓名:" prop="userName">
          <el-input v-model="adminUser.userName" placeholder="请输入真实姓名" />
        </el-form-item>
        <el-form-item label="是否启用:" prop="userRoleId">
          <el-radio-group v-model="adminUser.notState">
            <el-radio :label="false">启用</el-radio>
            <el-radio :label="true">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="角色:" prop="userRoleId">
          <el-radio-group v-model="adminUser.userRoleId">
            <el-radio
              v-for="(el, index) in listrole"
              :key="index"
              :label="el.id"
              style="margin-bottom: 10px"
              >{{ el.roleName }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submitAccountTypeForm('accountTypeDialog')"
          >确 定</el-button
        >
        <el-button @click="accountTypeDialogVisible = false" class="cancel_btn">取 消</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  partnerAccountTwoPage,
  updateUsers,
  notDelete,
  insertUser,
  notState,
  accoutlistById,
  listRoleGinseng
} from '@/api/systemUser'
import { pagination } from '@/mixins/pagination.js'

export default {
  name: 'account',
  mixins: [pagination],
  computed: {
    id() {
      return JSON.parse(sessionStorage.getItem('typeMode'))?.id
    }
  },
  data() {
    return {
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      value2: '',
      accountDialogVisible: false,
      accountTypeDialogVisible: false,
      accountType: '主账号',
      queryInfo: {
        partnerId: JSON.parse(sessionStorage.getItem('typeMode'))?.id,
        pageNum: 1,
        pageSize: 10,
        keys: '', //用户名/手机号
        orders: 1 //排序 默认传1 2->创建时间升序;3->创建时间降序
      },
      listData: [],
      total: 0,
      companyinfo: {},
      adminUser: {
        mobile: '', //	手机号
        notState: true, //状态
        partnerId: JSON.parse(sessionStorage.getItem('typeMode'))?.id, //合作伙伴编号
        userName: '', //用户名
        userRoleId: '', //用户角色编号
        userChannel: '2'
      },
      listrole: [],
      adminrules: {
        userName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
        userRoleId: [{ required: true, message: '请勾选角色', trigger: 'change' }],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入合法手机号',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.getCorrelateUser()
  },
  methods: {
    //查询系统角色
    async listRoleGinseng() {
      const query = {
        partnerId: this.id
      }
      const res = await listRoleGinseng(query)
      if (res.data.resultCode == 200) {
        this.listrole = res.data.data
      }
    },
    beforeAccountDialog(id) {
      this.currentId = id
      this.accountDialogVisible = true
    },
    //冻结账号
    accoutClick(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return notDelete(query)
        })
        .then(() => {
          this.getCorrelateUser()
          this.$message.success('操作成功')
        })
        .catch(() => {})
    },
    //账号启用禁用
    notState(row) {
      const query = {
        id: row.id
      }
      this.$alert('您是否确认操作？')
        .then(function () {
          return notState(query)
        })
        .then(() => {
          this.getCorrelateUser()
          this.$message.success('操作成功')
        })
        .catch(() => {
          this.getCorrelateUser()
        })
    },
    async getCorrelateUser() {
      const res = await partnerAccountTwoPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.listData.forEach((el) => {
          el.notState = !el.notState
        })
      }
    },
    submitAccountTypeForm(formName) {
      this.adminUser.partnerId = this.id
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.adminUser.id) {
            insertUser(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.getCorrelateUser()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            updateUsers(this.adminUser).then(({ data: res }) => {
              if (res.resultCode === 200) {
                this.getCorrelateUser()
                this.accountTypeDialogVisible = false
              } else {
                this.$message.error(res.message)
              }
            })
          }
        }
      })
    },

    //新增账号
    addaccount() {
      this.accountTypeDialogVisible = true
      this.listRoleGinseng()
      this.adminUser = {
        mobile: '', //	手机号
        notState: true, //状态
        partnerId: '', //合作伙伴编号
        userName: '', //用户名
        userRoleId: '', //用户角色编号
        userChannel: '2'
      }
    },
    //编辑
    async editphone(row) {
      this.accountTypeDialogVisible = true
      this.listRoleGinseng()
      await this.accoutlistById(row.id)
    },
    //账号详情
    async accoutlistById(id) {
      const { data: res } = await accoutlistById({ id })
      if (res.resultCode == 200) {
        this.adminUser = res.data
      }
    },
    afteraccountDialogHandle() {
      this.ruleForm.date = null
      this.accountDialogVisible = false
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'loginCount') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 2
          this.getCorrelateUser()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 3
          this.getCorrelateUser()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = 1
          this.getCorrelateUser()
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.account_dialog {
  /deep/ .el-dialog__header {
    text-align: left;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
  }
  /deep/ .el-dialog__title {
    font-size: 16px !important;
    line-height: 22px !important;
    color: #252631 !important;
  }
}
.cancel_btn {
  border: 1px solid #4e93fb;
  color: #4e93fb;
}
.search_right {
  width: 560px;

  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
::v-deep .el_upload .el-form-item {
  margin-bottom: 0px;
}
::v-deep .name_form .el-input__suffix {
  position: absolute;
  height: 100%;
  right: -54px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-step__title {
  font-size: 14px;
  line-height: 38px;
}

::v-deep .name_form .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 556px;
}
::v-deep .name_form .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 496px;
}

.associated {
  background: #ffffff;
  .img_commer {
    display: flex;
    border: 1px solid #146aff;
    border-radius: 39px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #146aff;
    }
    .color_img {
      background: #146aff;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .img_commers {
    display: flex;
    border: 1px solid #ff9c00;
    border-radius: 39px;
    margin: 0px 10px;
    .name {
      font-size: 12px;
      line-height: 20px;
      color: #ff9c00;
    }
    .color_img {
      background: #ff9c00;
      height: 20px;
      width: 25px;
      border-radius: 20px;
      position: relative;
      left: -10px;
      img {
        width: 13px;
        height: 13px;
        position: relative;
        left: 5px;
        top: 2px;
      }
    }
  }
  .associated_top {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;
    // padding: 30px 0px 0px 0px;
    .account_cycle {
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      /* 灰色字体 */

      color: #969495;
    }
    .associated_img {
      display: flex;
      align-items: center;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;

      color: #151515;
      img {
        width: 67px;
        height: 54px;
        border-radius: 5px;
      }
    }
  }
  .switch_tab {
    background: #ffffff;
    margin-top: 20px;

    .essential_information {
      padding: 30px 40px;
      .general_input {
        width: 845px;
        border: 1px solid #d4d4d4;
        padding: 14px 16px;

        .input_flex {
          display: flex;
          justify-content: space-between;
          padding: 7px 0px;
          span {
            padding-left: 15px;
            font-size: 20px;
            cursor: pointer;
          }
        }
      }
      .img_tips {
        position: relative;
        top: -10px;
        color: #6ba1ff;
      }

      h4 {
        color: #146aff;
      }
      .dialog-footer {
        display: flex;
        justify-content: center;
      }
    }
  }

  .label_management {
    .new_page {
      margin: 20px auto;
      width: 100%;
      text-align: center;
    }

    .input-with {
      width: 100%;
      overflow: hidden;
      padding: 20px 0px;

      .input-with-select {
        width: 400px;
        float: left;
      }

      .seach {
        background-color: #448aff;
        color: #fff;
        border-radius: 0;
        border: 1px solid #448aff;
      }
    }
  }
}
</style>
